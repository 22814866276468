import * as styles from "./twoColumnLayout.module.css"
import { ContentTwoColumnLayout } from "../../types/content.types"
import { FC } from "react"
import React from "react"
import BlockContent from "../blockContent/blockContent"

const TwoColumnLayout: FC<ContentTwoColumnLayout> = ({columnOne, columnTwo}) => {
  return <div className={styles.twoColumn}>
    <div className={styles.col1}>
      <BlockContent blocks={columnOne} />
    </div>
    <div className={styles.col2}>
      <BlockContent blocks={columnTwo} />
    </div>
  </div>
}

export default TwoColumnLayout