import React, { ReactNode, useContext } from "react"
import { Link } from "gatsby"
import { Content } from "../../types/content.types"
import { PageContext } from "../../contexts/pageContext"
import { generatePath } from "../../utils/urlBuilder"

type Props = {
  className?: string
  children?: ReactNode
  reference: Content
  text?: string
  hideText?: boolean
}

const InternalLink: React.FC<Props> = ({ reference: ref, className, text, children, hideText }) => {
  const context = useContext(PageContext)
  return (
    <Link to={generatePath(ref, context.settings, context.categories)} className={className}>
      {!hideText && <>{text || ref.linkText || ref.title}</>}
      {children}
    </Link>
  )
}

export default InternalLink
