import * as React from "react";

function SvgTimesSolid(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M7.219 5.781L5.78 7.22 14.563 16 5.78 24.781 7.22 26.22 16 17.437l8.781 8.782 1.438-1.438L17.437 16l8.782-8.781L24.78 5.78 16 14.563z" />
    </svg>
  );
}

export default SvgTimesSolid;
