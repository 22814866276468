import React from "react"
import { ContentBlockItem as ContentBlockItemType } from "../../types/content.types"
import * as styles from "./contentBlockItemWrapper.module.css"
import ContentBlockItem from "../contentBlockItem/contentBlockItem"
import { getClassByColor } from "../../utils/colorList"

const ContentBlockItemWrapper: React.FC<ContentBlockItemType & {
  className: string
  height?: number
  width?: number
}> = props => {
  const colorClass = props.backgroundColor ? getClassByColor(props.backgroundColor) : "neutral"
  return (
    <div className={props.className}>
      <ContentBlockItem
        {...props}
        className={` ${styles.cbiWrapper} content-${colorClass}`}
      />
    </div>
  )
}

export default ContentBlockItemWrapper
