import { ContentBlockLayout, ContentHero } from "../../types/content.types"
import React from "react"
import ContentBlockItem from "../contentBlockItem/contentBlockItem"
import * as styles from "./hero.module.css"
import { getClassByColor, getFgClassByColor } from "../../utils/colorList"

const Hero: React.FC<ContentHero> = (props) => {
  const { blockLayout, backgroundColor = "", image, blockContent } = props;
  const getImageHeightAndWidth = () => {
    if (
      blockLayout !== ContentBlockLayout.TextLeftImageRight &&
      blockLayout !== ContentBlockLayout.TextRightImageLeft
    ) {
      return {
        height: 600,
        width: 1640
      }
    }

    return {
      width: 1640,
      height: 1200,
    }
  }

  const getContentStyle = () => `content-${getClassByColor(backgroundColor)}`

  return (
    <ContentBlockItem
      className={[styles.hero, getContentStyle(), styles[blockLayout]].join(" ")}
      blockLayout={blockLayout}
      backgroundColor={backgroundColor}
      text={blockContent}
      image={image}
      lazyLoad={false}
      {...getImageHeightAndWidth()}
    />
  )
}

export default Hero
