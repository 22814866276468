export type ContentImageAsset = {
  id: string
  url: string
}

export type ContentImageHotspot = {
  x?: number
  y?: number
  width: number
  height: number
}

export type ContentImageCrop = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export type ContentImage = {
  asset?: ContentImageAsset
  hotspot?: ContentImageHotspot
  crop?: ContentImageCrop
}

export type ContentCategory = {
  id: string
  slug?: string
  baseColor?: string
  homepage?: Content
  parent?: ContentCategory
  title?: string
}

export type ContentExternalLink = {
  href: string
  blank?: boolean
}

export type ContentActionButton = {
  text?: string
  document?: Content | ContentExternalLink
}

export type IconTextLink = {
  icon?: string
  text?: string
  link?: ContentExternalLink
}

export type ContentContactDetails = {
  details?: Array<IconTextLink>
}

export type ContentBlockSpan = {
  marks?: Array<string>
  text: string
  _type?: string
}

export type ContentBlock = {
  children?: Array<ContentBlockSpan>
  style?: string
}

export type ContentContactForm = {
  text?: Array<ContentBlock>
}

export enum ContentBlockLayout {
  TextLeftImageRight = "left",
  TextRightImageLeft = "right",
  TextTopImageBottom = "top",
  TextBottomImageTop = "bottom",
  NoText = "noText",
  NoImage = "noImage",
}

export type ContentHero = {
  blockLayout: ContentBlockLayout
  backgroundColor: string
  image: ContentImage
  blockContent: Array<ContentBlock>
}

enum ContentInfoPointIcon {
  Quote = "quote",
  Info = "info",
  Question = "question",
  Exclamation = "exclamation",
}

export type ContentInfoPoint = {
  text?: string
  color?: string
  icon?: ContentInfoPointIcon
  actionLink?: ContentActionButton
}

export type ContentHeading = {
  title?: string
  backgroundColor?: string
  actionButton?: ContentActionButton
  headingSize?: string
}

export type ContentSeoImageProps = {
  caption?: string
  alt?: string
}

export type ContentSeoImage = ContentSeoImageProps & ContentImage

export type ContentGallery = {
  title?: string
  images?: Array<ContentSeoImage>
}

export type ContentGalleryBlock = {
  gallery?: ContentGallery
  hideCaptions?: boolean
  disableEnlarge?: boolean
}

export type ContentBlockItem = {
  id?: string
  _type?: string
  _key?: string
  blockLayout?: ContentBlockLayout
  backgroundColor?: string
  image?: ContentSeoImage
  text?: ContentAllBlocks
  linkTo?: Content
}

export type ContentBlockList = {
  contentBlockItems?: Array<ContentBlockItem | Content>
}

export type ContentProjectListItem = {
  title?: string
  subTitle?: string
  linkedItem?: Content
}

export type ContentProjectList = {
  items?: Array<ContentProjectListItem>
}

export type ContentAllBlocks = Array<
  | ContentBlock
  | ContentContactDetails
  | ContentContactForm
  | ContentHero
  | ContentInfoPoint
  | ContentHeading
  | ContentGalleryBlock
  | ContentBlockItem
  | ContentBlockList
  | ContentProjectList
  | ContentTwoColumnLayout
  | ContentActionButton
>

export type ContentTwoColumnLayout = {
  columnOne?: ContentAllBlocks
  columnTwo?: ContentAllBlocks
}

export type Content = {
  _type: string
  id: string
  title: string
  linkText?: string
  slug: string
  baseColor?: string
  description?: string
  image: ContentImage
  category: ContentCategory
  _rawContent?: ContentAllBlocks
  _rawExcerpt?: ContentAllBlocks
  excerpt?: ContentAllBlocks
}
