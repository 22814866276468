import * as styles from "./gallery.module.css"
import { ContentGallery, ContentGalleryBlock, ContentImage } from "../../types/content.types"
import React, { FC, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import Image from "../image/image"
import ExternalLink from "../link/externalLink"
import { FontIcon } from "../icons/icons"
import useGestures from "../../utils/hooks/useGestures"

type Props = {
  className?: string
  onViewImage?: (index, event) => void
}

const GalleryDisplay: FC<ContentGalleryBlock & Props> = ({
  gallery,
  disableEnlarge,
  hideCaptions,
  className = "",
  onViewImage,
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {gallery &&
        gallery.images &&
        gallery.images.map((image, i) => (
          <ExternalLink
            href={image.asset.url}
            text={""}
            className={styles.item}
            key={i}
            hideText={true}
            onClick={e => onViewImage(i, e)}
          >
            <Image {...image} height={400} width={400} className={styles.image} />
            {!hideCaptions && (
              <div className={styles.caption}>
                <span className={styles.title}>{image.caption}</span>
              </div>
            )}
          </ExternalLink>
        ))}
    </div>
  )
}

type ImageViewerProps = {
  image: ContentImage
  onClose?: () => void
  onNextImage?: () => void
  onPreviousImage?: () => void
}

const GalleryImageViewer: FC<ImageViewerProps> = ({
  image,
  onClose,
  onNextImage,
  onPreviousImage,
}) => {
  const imageRef = useRef()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.querySelector("body").classList.add(styles.overflow)
    return () => {
      document.querySelector("body").classList.remove(styles.overflow)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
  }, [image])

  useGestures(imageRef, {
    onSwipeLeft: onNextImage,
    onSwipeRight: onPreviousImage,
  })

  const appendTo = document.querySelector("body > div")
  console.dir(appendTo)
  return ReactDOM.createPortal(
    <div className={styles.portal} ref={imageRef}>
      <div className={styles.ivImage}>
        {loading && (
          <div className={styles.loading}>
            <span>Loading...</span>
          </div>
        )}
        <Image
          {...image}
          fit="contain"
          onLoad={() => setLoading(false)}
          onStartLoad={() => setLoading(true)}
          width={2048}
          height={1536}
        />
      </div>
      <div className={styles.ivPrev} onClick={onPreviousImage}>
        <FontIcon icon="chevron-left" />
      </div>
      <div className={styles.ivNext} onClick={onNextImage}>
        <FontIcon icon="chevron-right" />
      </div>
      <div className={styles.ivClose} onClick={onClose}>
        <FontIcon icon={"times"} />
      </div>
    </div>,
    appendTo
  )
}

const Gallery: FC<ContentGalleryBlock & { className?: string }> = props => {
  const [currentImage, setCurrentImage] = useState(null)
  const { gallery } = props
  const viewImage = (index, e) => {
    e.preventDefault()
    setCurrentImage(index)
    return false
  }
  return (
    <>
      <GalleryDisplay {...props} onViewImage={viewImage} />
      {currentImage != null && (
        <GalleryImageViewer
          image={gallery.images[currentImage]}
          onClose={() => setCurrentImage(null)}
          onNextImage={() =>
            setCurrentImage(currentImage + 1 >= gallery.images.length ? 0 : currentImage + 1)
          }
          onPreviousImage={() =>
            setCurrentImage(currentImage - 1 < 0 ? gallery.images.length - 1 : currentImage - 1)
          }
        />
      )}
    </>
  )
}

export default Gallery
