import { Settings } from "../types/settings.types"
import { Content } from "../types/content.types"
import { CategoryPaths } from "../types/category.types"

export const generatePath = (
  page: Content,
  settings: Settings,
  categories: CategoryPaths
): string => {
  if (settings.homepage && settings.homepage.id == page.id) {
    return `/`
  }

  if (page.category && page.category.homepage && page.category.homepage.id === page.id) {
    return `${categories[page.category.id].path}/`
  }

  if (page.category) {
    return `${categories[page.category.id].path}/${page.slug}`
  }

  return `/${page.slug}/`
}
