import React from "react"
import Img from "gatsby-image"
import { getFixedGatsbyImage, getFluidGatsbyImage } from "gatsby-source-sanity"
import { ContentImage, ContentSeoImage } from "../../types/content.types"
import urlForImage, { getFluidImage } from "../../utils/urlForImage"

// @ts-ignore
import sanityConfig from "../../../../studio/sanity.json"

const Image: React.FC<ContentSeoImage & {
  fit?: string
  width?: number
  height?: number
  lazyLoad?: boolean
  className?: string
  onLoad?: () => void
  onStartLoad?: () => void
}> = props => {
  const {
    asset,
    alt,
    fit,
    width,
    height,
    lazyLoad = true,
    className,
    onLoad = () => true,
    onStartLoad = () => true,
  } = props
  const fluidProps = getFluidGatsbyImage(
    asset.id,
    { maxWidth: width ? width : 1024, maxHeight: height ? height : 768 },
    sanityConfig.api
  )

  console.log(lazyLoad ? "lazy" : "eager");
  return (
    <Img
      fluid={fit === "contain" ? fluidProps : getFluidImage(props, fluidProps, width, height)}
      alt={alt}
      imgStyle={{ objectFit: fit || "cover" }}
      onStartLoad={onStartLoad}
      onLoad={onLoad}
      loading={lazyLoad ? "lazy" : "eager"}
      className={className}
    />
  )
}

export default Image
