import * as styles from "./contactForm.module.css"
import { ContentContactForm } from "../../types/content.types"
import React, { FC, useRef, useState } from "react"
import BlockContent from "../blockContent/blockContent"
import ContentActionButton, { Layout } from "../actionButton/contentActionButton"
import { navigate } from "gatsby-link"
import Recaptcha from "react-google-recaptcha"
import classnames from "classnames"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

const ContactForm: FC<ContentContactForm> = ({ text }) => {
  const [state, setState] = useState({})
  const [sending, setSending] = useState(false)
  const [errors, setErrors] = useState({})
  const recaptchaRef = React.createRef()
  const form = useRef()

  const encode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })

    if (errors[e.target.name]) {
      validateForm()
    }
  }

  const validateForm = () => {
    let errors = {}

    if (state["message"] == null || state["message"].length < 10) {
      errors["message"] = true
    }

    if (state["name"] == null || state["name"].length < 3) {
      errors["name"] = true
    }

    if (
      (state["email"] == null || state["email"].length < 8) &&
      (state["contactNumber"] == null || state["contactNumber"].length < 6)
    ) {
      errors["email"] = true
      errors["contactNumber"] = true
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e, target) => {
    e.preventDefault()

    if (validateForm() == false) return;

    setSending(true)

    const recaptchaValue = await recaptchaRef.current.executeAsync()

    /* Handles issues with page refresh and browser populating the forms, not triggering the change events */
    const currForm = target || e.target
    const inputs = currForm.querySelectorAll("input,textarea")

    inputs.forEach(elem => {
      if ("createEvent" in document) {
        const evt = document.createEvent("HTMLEvents")
        evt.initEvent("change", false, true)
        elem.dispatchEvent(evt)
      } else {
        elem.fireEvent("onchange")
      }
    })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": currForm.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        navigate(currForm.getAttribute("action"))
      })
      .catch(error => {
        alert(error)
        setSending(false)
      })
  }

  return (
    <div className={styles.contactForm}>
      {text && text.length > 0 && (
        <div className={styles.blockContent}>
          <BlockContent blocks={text} />
        </div>
      )}
      <form
        className={styles.form}
        ref={form}
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="enquiries" />
        <div>
          <label
            className={classnames({
              [styles.field]: true,
              [styles.error]: errors["name"] == true,
            })}
          >
            <span>Name</span>
            <input type="text" name="name" onChange={handleChange} />
            <div className={styles.errorMessage}>
              Please enter your name to help us keep track of who we're helping out
            </div>
          </label>
        </div>
        <div>
          <label className={styles.field}>
            <span>Location</span>
            <input type="text" name="location" onChange={handleChange} />
          </label>
        </div>
        <div>
          <label className={classnames({
            [styles.field]: true,
            [styles.error]: errors["contactNumber"] == true,
          })}>
            <span>Contact Number</span>
            <input type="text" name="contactNumber" onChange={handleChange} />
            <div className={styles.errorMessage}>
              Please enter a valid email address or contact number.
            </div>
          </label>
        </div>
        <div>
          <label className={classnames({
            [styles.field]: true,
            [styles.error]: errors["email"] == true,
          })}>
            <span>Email</span>
            <input type="text" name="email" onChange={handleChange} />
            <div className={styles.errorMessage}>
              Please enter a valid email address or contact number.
            </div>
          </label>
        </div>
        <div>
          <label className={classnames({
            [styles.field]: true,
            [styles.error]: errors["message"] == true,
          })}>
            <span>Message</span>
            <textarea name="message" onChange={handleChange} />
            <div className={styles.errorMessage}>
              Please tell us a bit about what you need help with; to kick start the conversation.
            </div>
          </label>
        </div>
        <div>
          <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
        </div>
        <div>
          {sending && <p>Sending, please wait...</p>}
          {!sending && (
            <ContentActionButton
              onClick={e => handleSubmit(e, form.current)}
              layout={Layout.FormButton}
              text="Submit"
            />
          )}
        </div>
      </form>
    </div>
  )
}

export default ContactForm
