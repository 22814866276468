import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import Hero from "../hero/hero"
import { ContentAllBlocks } from "../../types/content.types"
import * as styles from "./blockContent.module.css"
import ContentActionButton, { Layout } from "../actionButton/contentActionButton"
import ContentBlockItemWrapper from "../contentBlockItemWrapper/contentBlockItemWrapper"
import ContentBlockItemList from "../contentBlockItemList/contentBlockItemList"
import ContentHeading from "../contentHeading/contentHeading"
import InfoPointBlock from "../infoPointBlock/infoPointBlock"
import ProjectList from "../projectList/projectList"
import ContactDetails from "../contactDetails/contactDetails"
import ContactForm from "../contactForm/contactForm"
import TwoColumnLayout from "../twoColumnLayout/twoColumnLayout"
import Gallery from "../gallery/gallery"

type Props = {
  blocks: ContentAllBlocks
  context?: Object
}

const serializers = {
  marks: {
    externalLink: props => {
      const { mark, children } = props
      return (
        <a href={mark.href} target={mark.blank ? "_blank" : "_self"}>
          {children.join("")}
        </a>
      )
    },
  },
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>

        case "h2":
          return <h2>{props.children}</h2>

        case "h3":
          return <h3>{props.children}</h3>

        case "h4":
          return <h4>{props.children}</h4>

        case "blockquote":
          return <blockquote>{props.children}</blockquote>

        default:
          return <p>{props.children}</p>
      }
    },
    hero(props) {
      return (
        <Hero
          blockLayout={props.node.blockLayout}
          backgroundColor={props.node.backgroundColor}
          image={props.node.image}
          blockContent={props.node.blockContent}
        />
      )
    },
    galleryBlock({ node }) {
      return <Gallery {...node} />
    },
    contentBlockItem(props) {
      return <ContentBlockItemWrapper {...props.node} />
    },
    contentBlockItemList(props) {
      return <ContentBlockItemList {...props.node} />
    },
    actionButton({ node }) {
      return <ContentActionButton {...node} layout={Layout.LargeButton} />
    },
    actionLink({ node }) {
      return <ContentActionButton {...node} layout={Layout.Link} />
    },
    contentHeading({ node }) {
      node.context = node._context
      delete node._context
      return <ContentHeading {...node} />
    },
    infoPointBlock({ node }) {
      return <InfoPointBlock {...node} />
    },
    projectList({ node }) {
      return <ProjectList {...node} />
    },
    contactDetails({ node }) {
      return <ContactDetails {...node} />
    },
    contactForm({ node }) {
      return <ContactForm {...node} />
    },
    twoColumnLayout({ node }) {
      return <TwoColumnLayout {...node} />
    },
  },
}

const BlockContent: React.FC<Props> = ({ blocks, context }) => (
  <BaseBlockContent
    context={context}
    className={styles.main}
    blocks={blocks.map(b => ({ ...b, _context: context }))}
    serializers={serializers}
  />
)

export default BlockContent
