import { Content, ContentHeading as ContentHeadingModel } from "../../types/content.types"
import * as styles from "./contentHeading.module.css"
import React from "react"
import ContentActionButton, { Layout } from "../actionButton/contentActionButton"
import { getClassByColor } from "../../utils/colorList"
import { Link } from "gatsby"

const ContentHeading: React.FC<ContentHeadingModel & { context: Content }> = ({
  actionButton,
  backgroundColor,
  title,
  headingSize,
  context,
}) => {
  const CustomTag = `h${headingSize || "1"}` as keyof JSX.IntrinsicElements
  const isLargeHeading = backgroundColor && backgroundColor != ""
  const colorClass = isLargeHeading ? getClassByColor(backgroundColor) : ""
  return (
    <div
      className={[styles.heading, `content-${colorClass}`, isLargeHeading ? styles.large : ""].join(
        " "
      )}
    >
      <div className={styles.titleWrap}>
        {isLargeHeading && (
          <>
            {context.category && (
              <div className={styles.breadcrumb}>
                <Link to="/">Home</Link> / {context.category.title}
              </div>
            )}
          </>
        )}
        <CustomTag>{title || context.title}</CustomTag>
      </div>
      {actionButton && actionButton.text?.length > 0 && (
        <div className={styles.buttonWrap}>
          <ContentActionButton
            {...actionButton}
            layout={Layout.LargeButton}
            className={styles.button}
          />
        </div>
      )}
    </div>
  )
}

export default ContentHeading
