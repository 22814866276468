import React, { FC, ReactElement } from "react"
import { Link as GatsbyLink } from "gatsby"
import ExternalLink from "./externalLink"
type Props = {
  href: string
  blank?: boolean
  text?: string
  className?: string
}
const Link: FC<Props> = ({ href = "", blank, text, className = "", children }) => {
  return (
    <>
      {href.toLowerCase().substr(0, 1) == "/" && (
        <GatsbyLink to={href} className={className}>
          {text}
          {children}
        </GatsbyLink>
      )}
      {href.toLowerCase().substr(0, 1) != "/" && (
        <ExternalLink href={href} blank={blank} className={className}>
          {text}
          {children}
        </ExternalLink>
      )}
    </>
  )
}

export default Link
