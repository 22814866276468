import React, { FC } from "react"
import { IconTextLink as IconTextLinkProps } from "../../types/content.types"
import { FontIcon } from "../icons/icons"
import Link from "../link/link"
import * as styles from "./iconTextLink.module.css"

const IconTextLink: FC<IconTextLinkProps & { className?: string }> = ({
  icon,
  text,
  link,
  className = "",
}) => {
  return (
    <Link {...link} className={[styles.outer, className].join(' ')}>
      <FontIcon icon={icon} />
      {text && <span>{text}</span>}
    </Link>
  )
}

export default IconTextLink
