interface ColorSpec {
  title: string
  value: string
  class: string
}

const colorList: Array<ColorSpec> = [
  { title: "Teal", value: "#135C5A", class: "one" },
  { title: "Dark Blue", value: "#0b3751", class: "two" },
  { title: "Light Blue", value: "#c5dfe0", class: "three" },
  { title: "Green", value: "#5a781d", class: "four" },
  { title: "Salmon", value: "#fabeab", class: "five" },
  { title: "Orange", value: "#e9b260", class: "six" },
  { title: "Neutral", value: "#ffffff", class: "neutral" },
]

const defaultColorClass = "one"

export const getHexByClassName = (className: string): string => {
  const result: Array<ColorSpec> = colorList.filter(
    c => className && c.class == className.toLowerCase()
  )
  return result.length > 0 ? result[0].value : ""
}

export const getClassByColor = (color: string): string => {
  const result: Array<ColorSpec> = colorList.filter(c => color && c.value == color.toLowerCase())
  return result.length > 0 ? result[0].class : defaultColorClass
}

export const getFgClassByColor = (color: string): string => {
  return `${getClassByColor(color)}-fg`
}
