import React, { FC } from "react"
import { ContentProjectList } from "../../types/content.types"
import * as styles from "./projectList.module.css"
import Image from "../image/image"
import InternalLink from "../link/internalLink"

const ProjectList: FC<ContentProjectList & { className?: string }> = ({
  items,
  className = "",
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {items &&
        items.map((item, i) => (
          <InternalLink
            reference={item.linkedItem}
            text={""}
            className={styles.item}
            key={i}
            hideText={true}
          >
            <Image {...item.linkedItem.image} height={400} width={400} className={styles.image} />
            <div className={styles.caption}>
              <span className={styles.title}>{item.title || item.linkedItem.title}</span>
              <span className={styles.subTitle}>{item.subTitle}</span>
            </div>
          </InternalLink>
        ))}
    </div>
  )
}

export default ProjectList
