import * as styles from "./contactDetails.module.css"
import { ContentContactDetails } from "../../types/content.types"
import React, { FC } from "react"
import IconTextLink from "../iconTextLink/iconTextLink"

const ContactDetails: FC<ContentContactDetails> = ({ details }) => {
  return (
    <div className={styles.details}>
      <section>
        <h2>Contact Details</h2>
        {details && details.map(d => <IconTextLink {...d} key={d.text} />)}
      </section>
    </div>
  )
}

export default ContactDetails
