import * as styles from "./contentBlockItemList.module.css"
import {
  Content,
  ContentBlockItem,
  ContentBlockLayout,
  ContentBlockList,
} from "../../types/content.types"
import React from "react"
import ContentBlockItemWrapper from "../contentBlockItemWrapper/contentBlockItemWrapper"
import { getClassByColor, getHexByClassName } from "../../utils/colorList"

const ContentBlockItemList: React.FC<ContentBlockList> = ({ contentBlockItems }) => {
  return (
    <div className={styles.listContainer}>
      {contentBlockItems &&
        contentBlockItems.map(block => {
          let blockContent = block
          let key = block.id
          let accentColor = ""
          if (block._type === "content") {
            let content = block as Content
            accentColor = getClassByColor(
              content.baseColor || content.category ? content.category.baseColor : ""
            )
            blockContent = {
              image: content.image,
              blockLayout: ContentBlockLayout.TextBottomImageTop,
              text: [
                {
                  _type: "block",
                  style: "h3",
                  children: [
                    {
                      _type: "span",
                      text: content.title,
                    },
                  ],
                },
                ...(content.excerpt ? content.excerpt : []),
                {
                  _type: "actionLink",
                  text: "Details",
                  document: content,
                },
              ],
            }
          } else {
            let content = block as ContentBlockItem
            accentColor = getClassByColor(content.backgroundColor)
            key = (block as ContentBlockItem)._key
          }
          const accentClass = `accent${accentColor.charAt(0).toUpperCase()}${accentColor.slice(1)}`
          return (
            <ContentBlockItemWrapper
              key={key}
              height={300}
              width={520}
              {...blockContent}
              backgroundColor={getHexByClassName("neutral")}
              className={[styles.contentBlockItem, styles[accentClass]].join(" ")}
            />
          )
        })}
    </div>
  )
}

export default ContentBlockItemList
