import InternalLink from "../link/internalLink"
import React from "react"
import {
  Content,
  ContentActionButton as ContentActionButtonProps,
  ContentExternalLink,
} from "../../types/content.types"
import * as styles from "./actionButton.module.css"
import ExternalLink from "../link/externalLink"
import { IconArrowRight } from "../icons/icons"

export enum Layout {
  LargeButton = "large",
  Link = "link",
  FormButton = "formButton"
}

type Props = {
  className?: string
  layout?: Layout
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ContentActionButton: React.FC<ContentActionButtonProps & Props> = ({
  text,
  document: doc,
  layout,
  className,
  onClick
}) => {
  const classes = [styles.actionButton, className, styles[layout]].join(" ")
  if (layout == Layout.FormButton) {
    return <button onClick={onClick} className={classes}>
      {text}
      <IconArrowRight />
    </button>
  }
  if ((doc as ContentExternalLink).href) {
    return (
      <ExternalLink {...(doc as ContentExternalLink)} text={text} className={classes}>
        <IconArrowRight />
      </ExternalLink>
    )
  } else {
    return (
      <InternalLink reference={doc as Content} text={text} className={classes}>
        <IconArrowRight />
      </InternalLink>
    )
  }
}

export default ContentActionButton
