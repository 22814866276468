import { ContentInfoPoint } from "../../types/content.types"
import * as styles from "./infoPointBlock.module.css"
import React from "react"
import { BlobShape, LAExclamation, LAInfo, LAQuestion, LAQuote } from "../icons/icons"
import { getClassByColor } from "../../utils/colorList"
import ContentActionButton, { Layout } from "../actionButton/contentActionButton"

const InfoPointBlock: React.FC<ContentInfoPoint> = ({ text, color, icon, actionLink }) => {
  const colorClass = color ? getClassByColor(color) : "one"
  return (
    <div className={[styles.infoPoint, `content-${colorClass}`].join(" ")}>
      <div className={styles.graphic}>
        <div className={styles.bg}>
          <BlobShape />
        </div>
        <div className={styles.icon}>
          {icon == "quote" && <LAQuote />}
          {icon == "info" && <LAInfo />}
          {icon == "question" && <LAQuestion />}
          {icon == "exclamation" && <LAExclamation />}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.text}>{text}</div>
        {actionLink && actionLink.text !== "" && (
          <div className={styles.link}>
            <ContentActionButton {...actionLink} layout={Layout.Link} />
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoPointBlock
