import React from "react"
import * as icons from "../laicons"

export const IconArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M 18.71875 6.78125 L 17.28125 8.21875 L 24.0625 15 L 4 15 L 4 17 L 24.0625 17 L 17.28125 23.78125 L 18.71875 25.21875 L 27.21875 16.71875 L 27.90625 16 L 27.21875 15.28125 Z" />
  </svg>
)
export const LAQuote = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 32 32">
    <path d="M 10 8 C 6.699219 8 4 10.699219 4 14 L 4 24 L 14 24 L 14 14 L 6 14 C 6 11.78125 7.78125 10 10 10 Z M 24 8 C 20.699219 8 18 10.699219 18 14 L 18 24 L 28 24 L 28 14 L 20 14 C 20 11.78125 21.78125 10 24 10 Z M 6 16 L 12 16 L 12 22 L 6 22 Z M 20 16 L 26 16 L 26 22 L 20 22 Z" />
  </svg>
)

export const LAQuestion = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 32 32">
    <path d="M 16 4 C 11.671875 4 8 7.054688 8 11 L 8 14 L 14 14 L 14 11 C 14 10.851563 14.089844 10.636719 14.4375 10.40625 C 14.785156 10.175781 15.351563 10 16 10 C 16.652344 10 17.21875 10.175781 17.5625 10.40625 C 17.90625 10.636719 18 10.835938 18 11 C 18 11.578125 17.8125 11.980469 17.4375 12.4375 C 17.0625 12.894531 16.480469 13.367188 15.84375 13.90625 C 14.566406 14.988281 13 16.496094 13 19 L 13 20 L 19 20 L 19 19 C 19 18.660156 19.125 18.421875 19.5 18.03125 C 19.875 17.640625 20.496094 17.179688 21.15625 16.625 C 22.476563 15.515625 24 13.816406 24 11 C 24 7.089844 20.335938 4 16 4 Z M 16 6 C 19.394531 6 22 8.367188 22 11 C 22 13.144531 21.023438 14.101563 19.84375 15.09375 C 19.253906 15.589844 18.625 16.074219 18.0625 16.65625 C 17.714844 17.015625 17.445313 17.484375 17.25 18 L 15.3125 18 C 15.625 16.988281 16.234375 16.21875 17.15625 15.4375 C 17.769531 14.921875 18.4375 14.382813 19 13.6875 C 19.5625 12.992188 20 12.082031 20 11 C 20 10.039063 19.429688 9.242188 18.6875 8.75 C 17.945313 8.257813 17.003906 8 16 8 C 14.992188 8 14.050781 8.257813 13.3125 8.75 C 12.574219 9.242188 12 10.042969 12 11 L 12 12 L 10 12 L 10 11 C 10 8.316406 12.597656 6 16 6 Z M 13 22 L 13 28 L 19 28 L 19 22 Z M 15 24 L 17 24 L 17 26 L 15 26 Z" />
  </svg>
)

export const LAInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 32 32">
    <path d="M 16 4 C 13.800781 4 12 5.800781 12 8 C 12 10.199219 13.800781 12 16 12 C 18.199219 12 20 10.199219 20 8 C 20 5.800781 18.199219 4 16 4 Z M 16 6 C 17.117188 6 18 6.882813 18 8 C 18 9.117188 17.117188 10 16 10 C 14.882813 10 14 9.117188 14 8 C 14 6.882813 14.882813 6 16 6 Z M 11 13 L 11 18 L 13 18 L 13 23 L 11 23 L 11 28 L 21 28 L 21 23 L 19 23 L 19 13 Z M 13 15 L 17 15 L 17 25 L 19 25 L 19 26 L 13 26 L 13 25 L 15 25 L 15 16 L 13 16 Z" />
  </svg>
)

export const LAExclamation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 32 32">
    <path d="M 13 4 L 13 20 L 19 20 L 19 4 Z M 15 6 L 17 6 L 17 18 L 15 18 Z M 13 22 L 13 28 L 19 28 L 19 22 Z M 15 24 L 17 24 L 17 26 L 15 26 Z" />
  </svg>
)

export const BlobShape = () => (
  <svg
    viewBox="0 0 866 1288"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M107.21139072 1153.803C-25.3898544 990.483-36.42268992 748.796 82.13267344 572.151c41.60913248-71.9 63.42258256-162.526 79.44122992-232.698 40.4652424-194.335 155.492652-355.156 320.67017024-338.228 508.2723296 52.087 433.6212048 950.848 219.7566688 1152.658-156.8688784 148.02-426.96822288 206.62-594.78935168-.08z"
      fill="#ffddd3"
      fillOpacity=".99"
    />
  </svg>
)

export const FontIcon = ({ icon }) => {
  const findIcon = text => {
    let exactMatch = ""
    let partialMatch = ""
    text = text.replace(/[^a-zA-Z0-9]+/g, "")
    Object.keys(icons).forEach(i => {
      if (i.toLowerCase() == text) {
        exactMatch = icons[i]
      } else if (i.toLowerCase() == `${text}solid`) {
        partialMatch = icons[i]
      }
    })
    return exactMatch ? exactMatch : partialMatch
  }

  const Icon = icons[icon] ? icons[icon] : findIcon(icon)

  if (Icon) {
    return <Icon />
  }

  return <span/>
}
