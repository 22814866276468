import urlBuilder from "@sanity/image-url"
import { ContentSeoImage } from "../types/content.types"

// @ts-ignore
import sanityConfig from "../../../studio/sanity.json"

const urlForImage = (source: ContentSeoImage) => {
  const isBrowser = typeof window !== "undefined"
  const builder = urlBuilder(sanityConfig.api).image(source)
  const dpr = 1 //isBrowser ? Math.min(Math.ceil(window.devicePixelRatio || 1), 3) : 1
  return dpr > 1 ? builder.dpr(dpr) : builder
}

export const getFluidImage = (
  rawImage: ContentSeoImage,
  fluidProps: any,
  width?: number,
  height?: number
) => {
  if (rawImage.asset.url.slice(-3) == "svg") {
    return {
      src: rawImage.asset.url,
    }
  }
  const url = urlForImage(rawImage)
    .width(width)
    .height(height)
    .quality(80)
    .auto("format")
    .url()

  const rect = new URL(url).searchParams.get("rect")

  const addRectToUrl = (rect: string | null) => (incomingUrl: string) => {
    if (rect === null) return incomingUrl

    return incomingUrl.replace("?", `?rect=${rect}&q=80&`)
  }
  const convertUrl = addRectToUrl(rect)

  const addRectToUrlSet = (rect: string | null) => (incomingUrl: string) =>
    rect == null || rect == undefined
      ? incomingUrl
      : incomingUrl
          .split(",")
          .map(convertUrl)
          .join(",")

  const convertUrlSet = addRectToUrlSet(rect)

  return {
    ...fluidProps,
    src: convertUrl(fluidProps.src),
    srcSet: convertUrlSet(fluidProps.srcSet),
    srcSetWebp: convertUrlSet(fluidProps.srcSetWebp),
    srcWebp: convertUrl(fluidProps.srcWebp),
  }
}

export default urlForImage
