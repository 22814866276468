import React from "react"
import { Content } from "../types/content.types"
import { Settings } from "../types/settings.types"
import { CategoryPaths } from "../types/category.types"

export type PageContextData = {
  page: Content
  settings: Settings
  categories: CategoryPaths
}

export const PageContext = React.createContext<PageContextData>({
  page: null,
  settings: null,
  categories: null,
})
