import React, { ReactNode } from "react"
import { ContentExternalLink } from "../../types/content.types"

type Props = {
  className?: string
  children?: ReactNode
  text?: string
  hideText?: boolean
  onClick?: (event) => void
}

const ExternalLink: React.FC<ContentExternalLink & Props> = ({
  blank,
  href,
  className,
  text,
  hideText = false,
  children,
  onClick,
}) => {
  return (
    <a
      href={href}
      {...(blank ? { target: "_blank" } : {})}
      className={className}
      {...(onClick ? { onClick } : {})}
    >
      {!hideText && text}
      {children}
    </a>
  )
}

export default ExternalLink
