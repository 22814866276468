import {
  ContentBlockItem as ContentBlockItemType,
  ContentBlockLayout,
} from "../../types/content.types"
import React from "react"
import Image from "../image/image"
import BlockContent from "../blockContent/blockContent"
import * as styles from "./contentBlockItem.module.css"

const ContentBlockItem: React.FC<ContentBlockItemType & {
  lazyLoad?: boolean
  className: string
  height?: number
  width?: number
}> = ({
  blockLayout,
  backgroundColor,
  children,
  image,
  linkTo,
  text,
  lazyLoad= true,
  className,
  height = 1000,
  width = 1640,
}) => {
  return (
    <div className={[className, styles.blockWrap, styles[blockLayout], `content-${backgroundColor}`].join(" ")}>
      {blockLayout !== ContentBlockLayout.NoImage && (
        <div className={styles.image}>
          <Image {...image} height={height} width={width} lazyLoad={lazyLoad} />
        </div>
      )}
      {blockLayout !== ContentBlockLayout.NoText && (
        <div className={styles.text}>
          <BlockContent blocks={text} />
        </div>
      )}
    </div>
  )
}

export default ContentBlockItem
